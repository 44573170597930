.bg-abu{
	background: #4B3978;
}

.bg-abu2{
	background: #ABA4F7;
}

.bg-abu3{
	background:#F9E6F8;
}

.intro {
	display: table;
	width: 100%;
	height: 100%;
	padding: 0;
	background: url(../img/intro-bg.png) center center no-repeat;
	background-size: 100%;
	background-color: #e5e5e5;
}
.Title-Landing{
	color:#4B3978;
}
a:link, a:visited, a:active {
    text-decoration:none;
}
.validations{
	margin-top: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.text-center-card{
	text-align:center
}
@media only screen and (max-width: 449.98px) {
	.button-header{
		margin-top: 248px;
		padding-left: 1em !important;
		padding-right: 1em !important;
		min-height: 39px;
		margin-bottom: 5px;
		border-width: 1px;
		border-style: solid;
		font-weight: bold;
		background-color:  #4B3978;
		color: #ABA4F7;
		border-color: #4B3978;
		text-align:center!important;
		font-size: 13px;
		border-radius:9999px !important;
		align-items: center;
		cursor: pointer;
	}
	.text-center-cell{
		text-align:center!important
	}
}

@media only screen and (min-width: 450px) and (max-width: 853.98px) {
	.button-header{
		margin-top: 300px;
		padding-left: 3em !important;
		padding-right: 3em !important;
		min-height: 39px;
		margin-bottom: 5px;
		border-width: 1px;
		border-style: solid;
		font-weight: bold;
		background-color:  #4B3978;
		color: #ABA4F7;
		border-color: #4B3978;
		text-align:center!important;
		font-size: 13px;
		border-radius:9999px !important;
		align-items: center;
		cursor: pointer;
	}
	.text-center-cell{
		text-align:center!important
	}
}
@media only screen and (min-width: 854px) and (max-width: 1167.98px) {
	.button-header{
		margin-top: 350px;
		padding-left: 3em !important;
		padding-right: 3em !important;
		min-height: 39px;
		margin-bottom: 5px;
		border-width: 1px;
		border-style: solid;
		font-weight: bold;
		background-color:  #4B3978;
		color: #ABA4F7;
		border-color: #4B3978;
		text-align:center!important;
		font-size: 15px;
		border-radius:9999px !important;
		align-items: center;
		cursor: pointer;
	}
	.text-center-cell{
		text-align:center!important
	}
}

@media only screen and (min-width: 1168px) and (max-width: 1271.98px) { 
	.button-header{
		margin-top: 500px;
		padding-left: 2em !important;
		padding-right: 2em !important;
		min-height: 39px;
		margin-bottom: 5px;
		border-width: 1px;
		border-style: solid;
		font-weight: bold;
		background-color:  #4B3978;
		color: #ABA4F7;
		border-color: #4B3978;
		text-align:center!important;
		font-size: 15px;
		border-radius:9999px !important;
		align-items: center;
		cursor: pointer;
	}
	.text-center-cell{
		text-align:center!important
	}
}

@media only screen and (min-width: 1272px) and (max-width: 1919.98px) { 
	.button-header{
		margin-top: 550px;
		padding-left: 5em !important;
		padding-right: 5em !important;
		min-height: 39px;
		margin-bottom: 5px;
		border-width: 1px;
		border-style: solid;
		font-weight: bold;
		background-color:  #4B3978;
		color: #ABA4F7;
		border-color: #4B3978;
		text-align:center!important;
		font-size: 15px;
		border-radius:9999px !important;
		align-items: center;
		cursor: pointer;
	}
	.text-center-cell{
		text-align:center!important
	}
}

@media only screen and (min-width: 1920px) { 
	.button-header{
		margin-top: 700px;
		padding-left: 5em !important;
		padding-right: 5em !important;
		min-height: 39px;
		margin-bottom: 5px;
		margin-left: 310px !important;
		border-width: 1px;
		border-style: solid;
		font-weight: bold;
		background-color:  #4B3978;
		color: #ABA4F7;
		border-color: #4B3978;
		text-align: center;
		font-size: 15px;
		border-radius:9999px !important;
		align-items: center;
		cursor: pointer;
	}
}


